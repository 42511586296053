import React, { useEffect, useState } from 'react';
import { TranscriptionSegment } from "components";
import { useDispatch, useSelector } from "react-redux";
import { generalActions } from "reduxStore/reducers/generalSlice";
import { generalExtraActions } from "reduxStore/actions";
import { utils } from "common";
import { COMPLETED, FAILED, PENDING, TRANSCRIPTION_STATUS } from "common/constants";
import "components/transcriptionSection/_transcriptionSection.scss";

function TranscriptionSection({transcriptionId, currentTime, setAbsoluteIndex}) {
  const [selectedSegments, setSelectedSegments] = useState([]);
  const dispatch = useDispatch();
  const {transcriptionData} = useSelector(state => state.general);
  const [editMode, setEditMode] = useState(false);
  const [relevantSegments, setRelevantSegments] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSaveTranscription = () => {
    dispatch(generalExtraActions.audioTranscriptionUpdate({
      id: transcriptionId,
      data: {transcription: transcriptionData.transcription}
    }));
  }


  const handleCheckboxSelection = (index) => {
    setSelectedSegments(
      selectedSegments.includes(index) ? selectedSegments.filter((i) => i !== index) : [...selectedSegments, index]
    );
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    dispatch(generalActions.cancelTranscriptionChanges());
  };

  const handleSave = () => {
    setEditMode(false);
    handleSaveTranscription();
  };

  const handleRemove = () => {
    dispatch(generalActions.removeTranscriptionSegments({indices: selectedSegments}));
    setSelectedSegments([]);
  };

  const handleMerge = () => {
    dispatch(generalActions.mergeTranscriptionSegments({indices: selectedSegments}));
    setSelectedSegments([]);
  };

  const handleRetranscribe = () => {
    try {
      dispatch(generalExtraActions.audioRetranscribe(transcriptionId)).then((response) => {
        if (response?.type === "general/audioRetranscribe/fulfilled") {
          dispatch(generalExtraActions.audioTranscriptList());
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let segments = transcriptionData ? transcriptionData.transcription : []
    if (editMode) {
      setRelevantSegments(segments)
      const index = utils.getNewSegmentIndex(segments, currentTime);
      setCurrentIndex(index);
      setAbsoluteIndex(index);
    } else {
      const [rSegments, index, absoluteIndex] = utils.getRelevantSegments(segments, currentTime);
      setCurrentIndex(index);
      setAbsoluteIndex(absoluteIndex)
      setRelevantSegments(rSegments);
    }
  }, [currentTime, transcriptionData, editMode]);


  return (
    <div className="transcription-section">
      <div className="transcription-actions">
        <h1>
          Transcribed
          text {transcriptionId && transcriptionData?.transcription_status !== undefined && `(${TRANSCRIPTION_STATUS[transcriptionData?.transcription_status] || ''})`}
        </h1>
        <div className="actions">
          {
            transcriptionId && (transcriptionData?.transcription_status === FAILED) &&
            <a onClick={handleRetranscribe}>Retry</a>
          }
          {
            transcriptionId && transcriptionData?.transcription_status === PENDING &&
            <a onClick={handleRetranscribe}>Transcribe</a>
          }
          {
            transcriptionId && transcriptionData?.transcription_status === COMPLETED ? editMode ? <>
                <a onClick={handleMerge}>Merge</a>
                <a onClick={handleRemove}>Remove</a>
                <a onClick={handleCancel}>Cancel</a>
                <a onClick={handleSave}>Save</a>
              </> :
              <a onClick={handleEdit}>Edit</a> : null
          }
        </div>
      </div>
      <div className="transcription-text">
        {transcriptionData?.transcription_status === COMPLETED && <ul className="transcription-segment">
          {relevantSegments?.map(({key, text, speaker}, index) => {
            return <TranscriptionSegment
              key={key}
              canEdit={editMode}
              segmentTime={key}
              segmentSpeaker={speaker}
              segmentText={text}
              editMode={editMode}
              isActive={index === currentIndex}
              index={index}
              speakers={transcriptionData?.speakers}
              speakerMap={transcriptionData?.speakerMap}
              handleCheckboxSelection={() => {
                handleCheckboxSelection(index)
              }}
            />
          })}
        </ul>}
      </div>
    </div>
  );
}

export default TranscriptionSection;